import { EnumItem, Make_GetEnumItem } from "../../Enum";

export class CraftSearchOption {
    filter: CraftFilter = new CraftFilter();
    order: CraftOrder = new CraftOrder();
}

export class CraftFilter {
    collected: boolean = false;
    noted: boolean = false;
    wrong: boolean = false;
    in_higher: boolean = false;
    card_kind: number = 0;
    card_range_kind: number = 0;
    collect_ids: string[] = [];
}

export enum FilterCardKind {
    Finish = 1,
    Collect = 2,
}

export const FilterCardItems = [
    new EnumItem(FilterCardKind.Finish, "背过"),
    new EnumItem(FilterCardKind.Collect, "收藏"),
];

export class CraftOrder {
    kind: number = 0;
    is_desc: boolean = false;
}

export enum CraftOrderKind {
    CollectTime = 1, // 收藏时间倒序
    NoteTime = 2, // 笔记时间倒序
    WrongTime = 3, // 错题时间倒序
    CardFinishTime = 4, // 闪卡背完时间倒序
    CardCollectTime = 5, // 闪卡收藏时间倒序
    Mastery = 6, // 掌握程度从小到大
    WrongCount = 7, // 做错次数从大到小
    UsedCount = 8, // 练习次数从大到小
}

export const CraftOrderKindItems = [
    new EnumItem(CraftOrderKind.CollectTime, "收藏时间"),
    new EnumItem(CraftOrderKind.NoteTime, "笔记时间"),
    new EnumItem(CraftOrderKind.WrongTime, "错题时间"),
    new EnumItem(CraftOrderKind.CardFinishTime, "闪卡完成时间"),
    new EnumItem(CraftOrderKind.CardCollectTime, "闪卡收藏时间"),
    new EnumItem(CraftOrderKind.Mastery, "掌握程度"),
    new EnumItem(CraftOrderKind.WrongCount, "做错次数"),
    new EnumItem(CraftOrderKind.UsedCount, "练习次数"),
];

export const CraftOrderKindGetItem = Make_GetEnumItem(CraftOrderKindItems);
