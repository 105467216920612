import { Craft, IssueResult } from "../user/user_craft";
import { ShortWord } from "../word/word";
import { Answer, QuestionData } from "./data/QuestionData";

export class Question {
    id: string = "";
    data: QuestionData = new QuestionData();
    action: QuestionAction = new QuestionAction();
    result: QuestionResult = new QuestionResult();
    // index: number = 0;// 前端数据中在Section中的位置（连续）
    index_key: number = 0; // 后台数据中在Section中的位置（可能不连续）
    word_info: WordQuestionInfo = new WordQuestionInfo();
    connect_words: ShortWord[] = [];
    craft: Craft = new Craft();
    skip: boolean = false;
}

export class QuestionInfo {
    id: string = "";
    brief: string = "";
    category: number = 0;
    category_str: string = "";
    craft: Craft = new Craft();
    locked: boolean = false;
    selected: boolean = false;
}

export class WordQuestionInfo {
    kind: number = 0;
    id: string = "";
    option_ids: string[] = [];
    from_question_id: string = "";
    pair_id: string = "";
}

export enum WordQuestionKind {
    None = 0,
    Filling = 1,
    SixTwo = 2,
    Math = 3,
}

export class QuestionAction {
    answer: Answer = new Answer();
    flagged: boolean = false;
    used_time_seconds: number = 0;
    state: number = 0;
    answered_time_str: string = "";
}

export enum QuestionState {
    NotEncountered = 0,
    NotAnswered = 1,
    Answered = 2,
}

export class QuestionResult {
    correct: boolean = false;
    issue: IssueResult = new IssueResult();
}
