import { PageControl } from "../../model/question/search/SearchData";
import { Inbox, Mail, MailRace } from "../../model/statistic/mail/mail";
import { CreateNotice } from "../../model/statistic/mail/notice";

export interface GetInboxReq {}

export interface GetInboxRsp {
    inbox: Inbox;
}

export interface MarkReadReq {
    race: number;
    ids: string[];
    read_all: boolean;
}

export interface MarkReadRsp {}

export class GetMailsReq {
    race: number = MailRace.Remind;
    page_control: PageControl = new PageControl();
}

export class GetMailsRsp {
    mails: Mail[] = [];
    page_control: PageControl = new PageControl();
    unread_count: number = 0;
    isBackendData: boolean = false;
}

export interface SendNoticeReq {
    send_kind: number;
    group_id: string;
    user_ids: string[];
    create_notice: CreateNotice;
}

export enum SendKind {
    Users = 1,
    Group = 2,
}

export interface SendNoticeRsp {
    send_count: number;
}
