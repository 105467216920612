import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { TradeKind } from "../../model/security/TradeKind";

export function isDialogAction(action: AnyAction): boolean {
    return action.type.startsWith("dialog");
}

export interface PayDialogParam {
    show: boolean;
    kind: TradeKind;
}

function getInitState() {
    return {
        showPermission: { show: false, isLimit: false },
        vip: false,
        outOfLimit: { show: false, subject: "", roleName: "" },
        pay: { show: false, kind: TradeKind.None } as PayDialogParam,
        rights: false,
        loginHint: false,
        createFlash: { show: false, rangeKind: 1 },
        payFlash: false,
        upload: false,
        pick: { show: false, tempID: "" },
        simulateNoPermission: false,
        support: false,
        collect: false,
    };
}

export const dialogRuntimeSlice = createSlice({
    name: "dialog",
    initialState: getInitState(),
    reducers: {
        actionSetShowPermissionDialog: (
            state,
            action: PayloadAction<{ show: boolean; isLimit: boolean } | boolean>
        ) => {
            if (typeof action.payload === "boolean") {
                state.vip = action.payload;
                // state.showPermissionDialog = { show: action.payload, isLimit: false };
                return;
            }
            state.vip = action.payload.show;
            // state.showPermissionDialog = action.payload;
        },
        actionSetVIPDialog: (state, action: PayloadAction<boolean>) => {
            state.vip = action.payload;
        },
        actionSetPayDialog: (state, action: PayloadAction<PayDialogParam>) => {
            state.pay = action.payload;
        },
        actionSetShowOutOfLimitDialog: (
            state,
            action: PayloadAction<{ show: boolean; subject: string; roleName: string }>
        ) => {
            state.outOfLimit = action.payload;
        },
        actionSetRightsDialog: (state, action: PayloadAction<boolean>) => {
            state.rights = action.payload;
        },
        actionSetLoginHintDialog: (state, action: PayloadAction<boolean>) => {
            state.loginHint = action.payload;
        },
        actionSetCreateFlashDialog: (
            state,
            action: PayloadAction<{ show: boolean; rangeKind: number }>
        ) => {
            state.createFlash = action.payload;
        },
        actionSetPayFlashDialog: (state, action: PayloadAction<boolean>) => {
            state.payFlash = action.payload;
        },
        actionSetUploadDialog: (state, action: PayloadAction<boolean>) => {
            state.upload = action.payload;
        },
        actionSetPickDialog: (state, action: PayloadAction<{ show: boolean; tempID: string }>) => {
            state.pick = action.payload;
        },
        actionSetSimulateNoPermisson: (state, action: PayloadAction<boolean>) => {
            state.simulateNoPermission = action.payload;
        },
        acitonSetSupport: (state, action: PayloadAction<boolean>) => {
            state.support = action.payload;
        },
        acitonSetCollectDialog: (state, action: PayloadAction<boolean>) => {
            state.collect = action.payload;
        },
    },
});

export const {
    actionSetShowPermissionDialog,
    actionSetVIPDialog,
    actionSetShowOutOfLimitDialog,
    actionSetPayDialog,
    actionSetRightsDialog,
    actionSetLoginHintDialog,
    actionSetCreateFlashDialog,
    actionSetPayFlashDialog,
    actionSetUploadDialog,
    actionSetPickDialog,
    actionSetSimulateNoPermisson,
    acitonSetSupport,
    acitonSetCollectDialog,
} = dialogRuntimeSlice.actions;

export default dialogRuntimeSlice.reducer;
