import { Question, QuestionInfo } from "../Question";
import { SearchOption } from "./Option";

export class SearchReqData {
    search_option: SearchOption = new SearchOption();
    page_control: PageControl = new PageControl();
}

export class SearchRspData {
    is_backend_data: boolean = false;
    question_infos: QuestionInfo[] = [new QuestionInfo()];
    page_control: PageControl = new PageControl();
    total_count: number = 0;
}

export class PageControl {
    page_size: number = 0;
    page_index: number = 0;
    total_page: number = 0;
    limit: number = 0;
}

export enum SearchKind {
    Question = 0,
    Word = 1,
}

export class SearchQuestionsRsp {
    rsp: SearchRspData = new SearchRspData();
}

export class GetQuestionDetailRsp {
    question: Question = new Question();
}
