import { Button } from "@mui/material";
import dayjs from "dayjs";
import { methodUpdateCraft } from "../../api/apicraft/api";
import { AlertSuccess } from "../../bblib/component/Snackbar";
import { BookKind } from "../../model/book/Book";
import { WordQuestionKind } from "../../model/question/Question";
import { Improvement } from "../../model/user/review";
import { Craft, Note, QuestionCollect, UpdateCraftKind } from "../../model/user/user_craft";
import { selectBook } from "../book/selector";
import { _actionSetConnectWordCraft } from "../exam/reducer";
import { AppThunk } from "../hooks";
import { RootState } from "../ReduxStore";
import { DisplayMode } from "../root/reducer";
import { _actionSetSidebarConnectWordCraft } from "../search/reducer";
import { doUpdateCollect, doUpdateCraft, doUpdateNote, makeSource } from "./action_tool";
import { selectDisplayMode, selectQuestion } from "./selector";

// ------- Collect -------

const successHint = `收藏成功`;

export const actionSetQuestionCollected = (
    collected: boolean,
    openDialog?: () => void
): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        doUpdateCollect(getState(), dispatch, (c: QuestionCollect) => {
            let c2 = { ...c, collected: collected };
            if (!collected) {
                c2.collect_ids = [];
            }
            return c2;
        }).then(() => {
            if (collected) {
                AlertSuccess(successHint, () => (
                    <Button variant="text" color="bborange" onClick={openDialog}>
                        设置标签
                    </Button>
                ));
            }
        });
    };
};

export const actionSetCardCollected = (collected: boolean, rangeKind: number): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        doUpdateCraft(dispatch, state, UpdateCraftKind.Card, (craft: Craft) => {
            const craft2 = structuredClone(craft);
            craft2.card.range_kind = rangeKind;
            craft2.card.collected = collected;
            craft2.card.collect_time_stamp = collected ? dayjs().unix() : 0;
            return craft2;
        }).then(() => {
            if (collected) {
                AlertSuccess("收藏成功, 可在【我的闪卡-收藏】处查看");
            }
        });
    };
};

export const actionSetConnectWordCollect = (wordID: string, craft: Craft): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const book = selectBook(state);
        const displayMode = selectDisplayMode(state);
        const in_higher = book.template.kind === BookKind.Higher;
        const q = selectQuestion(state);
        const source_qid = q.data.id.startsWith("w") ? "" : q.data.id;
        const getSourceName = () => {
            if (displayMode === DisplayMode.SearchDispaly) {
                return "题目搜索";
            }
            if (book.title.length > 0) {
                return book.title + " " + book.sub_title;
            }
            return "";
        };

        const collect = craft.collect;
        const craft2 = {
            ...craft,
            collect: {
                ...collect,
                collected: !collect.collected,
                source_name: getSourceName(),
                update_time_stamp: dayjs().unix(),
                word: {
                    kind: WordQuestionKind.Filling,
                    word_id: wordID,
                    pair_id: "",
                    source_qid: source_qid,
                },
            },
            in_higher,
        } as Craft;

        return methodUpdateCraft(dispatch, {
            update_kind: UpdateCraftKind.Collect,
            craft_id: wordID,
            craft: craft2,
            source: makeSource(state),
        }).then((rsp) => {
            setConnectWordCraft(wordID, rsp.craft, getState(), dispatch);
            if (craft2.collect.collected) {
                AlertSuccess(successHint);
            }
        });
    };
};

function setConnectWordCraft(id: string, c: Craft, state: RootState, dispatch: any) {
    if (state.displayMode === DisplayMode.ExamDispaly) {
        dispatch(_actionSetConnectWordCraft({ id, c }));
        return;
    } else if (state.displayMode === DisplayMode.SearchDispaly) {
        dispatch(_actionSetSidebarConnectWordCraft({ id, c }));
        return;
    }
}

// -------- Note ------------

export const actionSetNoteReasonOld = (type: number, checked: boolean): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        doUpdateNote(getState(), dispatch, (note: Note) => {
            let reasons = structuredClone(note.reasons);
            for (let i = 0; i < reasons.length; i++) {
                if (reasons[i].type === type) {
                    reasons[i].checked = checked;
                }
            }

            return { ...note, reasons: reasons };
        });
    };
};

export const actionUpdateNoteText = (text: string, afterSuccess?: () => void): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        doUpdateNote(getState(), dispatch, (note: Note) => {
            const n2 = structuredClone(note);
            n2.text_input.note = text;
            return n2;
        }).then(() => {
            if (afterSuccess) {
                afterSuccess();
            }
        });
    };
};

export const actionUpdateNote = (note: Note, afterSuccess?: () => void): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        doUpdateNote(getState(), dispatch, () => {
            return note;
        }).then(() => {
            if (afterSuccess) {
                afterSuccess();
            }
        });
    };
};

export const actionUpdateImprovement = (im: Improvement): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        doUpdateCraft(dispatch, getState(), UpdateCraftKind.Improvement, (c: Craft) => {
            let c2 = structuredClone(c);
            c2.note.review.improvement = im;
            return c2;
        }).then(() => {
            AlertSuccess("改进方案保存成功");
        });
    };
};

// ------- Craft --------

export const actionUpdateCraft = (
    kind: UpdateCraftKind,
    updater: (c: Craft) => Craft
): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        return doUpdateCraft(dispatch, getState(), kind, updater);
    };
};
