import HelpIcon from "@mui/icons-material/Help";
import { Badge, BoxProps, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";

interface HelpProps {
    helpText: string;
    multiline?: boolean;
    placement?: TooltipProps["placement"];
    disable?: boolean;
    ml?: BoxProps["marginLeft"];
}
export function Help(props: PropsWithChildren<HelpProps>) {
    const { children, helpText, multiline, placement, disable, ml } = props;
    return disable ? (
        <>{children}</>
    ) : (
        <Badge
            badgeContent={
                <CustomWidthTooltip
                    title={helpText}
                    placement={placement}
                    sx={{
                        whiteSpace: multiline ? "pre-wrap" : undefined,
                    }}
                >
                    <HelpIcon color="bbgray2" sx={{ fontSize: 15, ml: ml }} />
                </CustomWidthTooltip>
            }
        >
            {children}
        </Badge>
    );
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});
