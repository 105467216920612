import { Book } from "../../model/book/Book";
import { Bank } from "../../model/question/flash/Bank";
import { Action, CreateOption, Flash, Round, Summary } from "../../model/question/flash/Flash";
import { Progress } from "../../model/statistic/SummaryData";
import { User } from "../../model/UserData";
import { API, apiRequest } from "../api";

const apiCheckCreateFlash: API<CheckCreateReq, CheckCreateRsp> = {
    url: "/api/flash/check_create",
};

export function methodCheckCreateFlash(dispatch: any, req: CheckCreateReq) {
    return apiRequest({ api: apiCheckCreateFlash, req, dispatch });
}

const apiCreateFlash: API<CreateFlashReq, CreateFlashRsp> = {
    url: "/api/flash/create_flash",
};

export function methodCreateFlash(dispatch: any, req: CreateFlashReq) {
    return apiRequest({ api: apiCreateFlash, req, dispatch, backdrop: true });
}

const apiOpenFlash: API<OpenFlashReq, OpenFlashRsp> = {
    url: "/api/flash/open_flash",
};

export function methodOpenFlash(dispatch: any, req: OpenFlashReq) {
    return apiRequest({ api: apiOpenFlash, req, dispatch, backdrop: true });
}

const apiGiveUpFlash: API<GiveUpFlashReq, GiveUpFlashRsp> = {
    url: "/api/flash/give_up_flash",
};

export function methodGiveUpFlash(dispatch: any, req: GiveUpFlashReq) {
    return apiRequest({ api: apiGiveUpFlash, req, dispatch, backdrop: true });
}

const apiUpdateChoice: API<UpdateChoiceReq, UpdateChoiceRsp> = {
    url: "/api/flash/update_choice",
};

export function methodUpdateChoice(dispatch: any, req: UpdateChoiceReq) {
    return apiRequest({ api: apiUpdateChoice, req, dispatch });
}

const apiFinishRound: API<FinishRoundReq, FinishRoundRsp> = {
    url: "/api/flash/finish_round",
};

export function methodFinishRound(dispatch: any, req: FinishRoundReq) {
    return apiRequest({ api: apiFinishRound, req, dispatch, backdrop: true });
}

const apiGetProgress: API<GetProgressReq, GetProgressRsp> = {
    url: "/api/flash/get_progress",
};

export function methodGetProgress(dispatch: any, req: GetProgressReq) {
    return apiRequest({ api: apiGetProgress, req, dispatch, backdrop: true });
}

interface CheckCreateReq {
    create_option: CreateOption;
    book_id: string;
}

interface CheckCreateRsp {
    count: number;
}

interface CreateFlashReq {
    select_ids: string[];
    range_kind: number;
    create_option: CreateOption;
    book_id: string;
}

interface CreateFlashRsp {
    flash: Flash;
    bank: Bank;
    user: User;
    book: Book;
}

interface OpenFlashReq {
    flash_id: string;
}

interface OpenFlashRsp {
    flash: Flash;
    bank: Bank;
}

interface UpdateChoiceReq {
    flash_id: string;
    round_index: number;
    action: Action;
    status: number;
}

interface UpdateChoiceRsp {}

interface FinishRoundReq {
    flash_id: string;
    round: Round;
}

interface FinishRoundRsp {
    flash: Flash;
    book: Book;
    progress: FinishProgress;
}

export class FinishProgress {
    title: string = "";
    before: Progress = new Progress();
    after: Progress = new Progress();
}

interface GiveUpFlashReq {
    flash_id: string;
}

interface GiveUpFlashRsp {
    book: Book;
}

interface GetProgressReq {
    flash_id: string;
}

interface GetProgressRsp {
    progress: Summary;
}
