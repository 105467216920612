import { Comment } from "./comment";

export class Post {
    id: string = "";
    topic_id: string = "";
    subject: Subject = new Subject();
    root_comment: Comment = new Comment();
    comment_count: number = 0;
    create_time_str: string = "";
    subscribed: boolean = false;
}

export class Subject {
    title: string = "";
    detail: string = "";
}

export class PostBrief {
    id: string = "";
    subject_brief: string = "";
    comment_brief: string = "";
    comment_count: number = 0;
    create_time_str: string = "";
    root_comment: Comment = new Comment();
    subscribed: boolean = false;
}

export interface PostInfo {
    id: string;
    create_time_str: string;
    root_comment: Comment;
    comment_count: number;
    subscribed: boolean;
}
