export class Inbox {
    remind: RaceInbox = new RaceInbox();
    notice: RaceInbox = new RaceInbox();
    isBackendData: boolean = false;
}

export class RaceInbox {
    race: number = 0;
    briefs: MailBrief[] = [];
    has_more: boolean = false;
}

export class MailBrief {
    id: string = "";
    race: number = 0;
    subject: string = "";
    content_brief: string = "";
    status: number = 0;
    link: string = "";
}

export class Mail {
    id: string = "";
    race: number = 0;
    subject: string = "";
    content: string = "";
    status: number = 0;
    link: string = "";
    create_time_str: string = "";
}

export enum MailRace {
    Remind = 1,
    Notice = 2,
    Chat = 3,
}

export enum MailStatus {
    Inbox = 0, // 收件箱未读
    Read = 1,
}
