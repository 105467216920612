import { RootState } from "../ReduxStore";

export const _selectFlashRuntime = (state: RootState) => state.flashRuntime;

export const selectFlashBook = (state: RootState) => _selectFlashRuntime(state).book;
export const selectFlashStage = (state: RootState) => _selectFlashRuntime(state).stage;
export const selectFlashShowResult = (state: RootState) => _selectFlashRuntime(state).showResult;
export const selectFlashFromURL = (state: RootState) => _selectFlashRuntime(state).fromURL;
export const selectFlashFinishProgress = (state: RootState) =>
    _selectFlashRuntime(state).finishProgress;

export const selectFlash = (state: RootState) => _selectFlashRuntime(state).flash;
export const selectFlashID = (state: RootState) => _selectFlashRuntime(state).flashID;
export const selectFlashRangeKind = (state: RootState) => selectFlash(state).range_kind;
export const selectFlashSummary = (state: RootState) => selectFlash(state).summary;

export const selectRoundIndex = (state: RootState) => _selectFlashRuntime(state).roundIndex;
export const selectRound = (state: RootState) => selectFlash(state).round;

export const selectRuntime = (state: RootState) => {
    const ri = selectRoundIndex(state);
    return selectRound(state).runtimes[ri];
};
export const selectRuntimeBankData = (state: RootState) => selectRuntime(state).bankData;
export const selectRuntimeQuestion = (state: RootState) => selectRuntimeBankData(state).question;

export const selectRuntimeQuestionData = (state: RootState) => selectRuntimeQuestion(state).data;
