import { Craft } from "../user/user_craft";

export class WordConnect {
    connect_ids: string[] = [];
    manual_updated: boolean = false;
    update_time_stamp: number = 0;
}

export class Word {
    id: string = "";
    index: number = 0;
    en: string = "";
    cn: string = "";
    kind: number = 0;
    connect_question_ids: string[] = [];
    forms: string[] = [];
    simplify_version: number = 0;
    translate_version: number = 0;
    group_id: number = 0;
    label: string = "";
    craft: Craft = new Craft();
    constructor(label: string = "") {
        this.label = label;
    }
}

export class WordInfo {
    id: string = "";
    en: string = "";
    cn: string = "";
    label: string = "";
    constructor(label: string = "") {
        this.label = label;
        this.en = label;
    }
}

export class ShortWord {
    info: WordInfo = new WordInfo();
    craft: Craft = new Craft();
}

export class WordBank {
    is_backend_data: boolean = false;
    wordMap: Map<string, WordInfo> = new Map(); // key:id
}

export enum WordSource {
    BBFilling = 3,
    BBSixTwo = 4,
    BBMath = 5,
}
