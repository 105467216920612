import { CraftSearchOption } from "./CraftSearch";
import { WordConnectSearchOption } from "./WordConnect";

export class SearchOption {
    kind: number = 0;
    basic: SearchBasicOption = new SearchBasicOption();
    question: SearchQuestionOption = new SearchQuestionOption();
    word: SearchWordOption = new SearchWordOption();
}

export class SearchBasicOption {
    key_words: string = "";
    craft: CraftSearchOption = new CraftSearchOption();
}

export class SearchQuestionOption {
    category: number = 0;
    kind: number = 0;
    difficulty: number = 0;
    visible: number = 0;
    tag_ids: string[] = [];
    ids: string[] = [];
    filter_paid: boolean = false;
    marks: number[] = [];
    word_connect: WordConnectSearchOption = new WordConnectSearchOption();
}

export class SearchWordOption {
    source: number = 0;
}
