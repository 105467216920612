import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageSource } from "../../model/Rate";
import { BookReport } from "../../model/Report";
import { IssueIdea } from "../../model/ResourceData";
import { UserDaily } from "../../model/statistic/Daily";
import { BackendTagBank, Tag, TagBank } from "../../model/TagData";
import { BackendReasonLib, ReasonLib } from "../../model/user/reason";
import { UserInfo } from "../../model/UserData";
import { WordBank, WordInfo } from "../../model/word/word";
import { bookRuntimeSlice, isBookAction } from "../book/reducer";
import { dialogRuntimeSlice, isDialogAction } from "../dialog/reducer";
import { examRuntimeSlice, isExamAction } from "../exam/reducer";
import { flashRuntimeSlice, isFlashActoin } from "../flash/reducer";
import { isMailAction, mailRuntimeSlice } from "../mail/reducer";
import { isManageActoin, manageRuntimeSlice } from "../manage/reducer";
import { isPostAction, postRuntimeSlice } from "../post/reducer";
import { isSearchAction, searchRuntimeSlice } from "../search/reducer";
import { isUserAction, userSlice } from "../user/reducer";

export interface IdeaBank {
    ideas: IssueIdea[];
    isBackendData: boolean;
}

export enum DisplayMode {
    NoneDisplay = 0,
    ExamDispaly = 1,
    SearchDispaly = 2,
    ReportDisplay = 3,
    FlashDiaplay = 4,
}

export class CalendarDays {
    days = [] as number[];
    is_backend_data: boolean = false;
}
export class SummaryBank {
    user_daily: UserDaily = new UserDaily();
    calendar_days: CalendarDays = new CalendarDays();
}

export class PermissionManageRuntime {
    users: UserInfo[] = [];
}

const rootSlice = createSlice({
    name: "root",
    initialState: {
        ideaBank: {
            ideas: [],
            isBackendData: false,
        } as IdeaBank,
        displayMode: DisplayMode.NoneDisplay,
        tagBank: new TagBank(),
        summaryBank: new SummaryBank(),
        calcDisplay: "",
        backdrop: false, // 全屏加载条
        backdropCount: 0,
        wordBank: new WordBank(),
        report: new BookReport(),
        pageSource: PageSource.None,
        reasonLib: new ReasonLib(),

        examRuntime: examRuntimeSlice.getInitialState(),
        searchRuntime: searchRuntimeSlice.getInitialState(),
        bookRuntime: bookRuntimeSlice.getInitialState(), // 打开的练习册
        user: userSlice.getInitialState(),
        flashRuntime: flashRuntimeSlice.getInitialState(),
        dialogRuntime: dialogRuntimeSlice.getInitialState(),
        manageRuntime: manageRuntimeSlice.getInitialState(),
        postRuntime: postRuntimeSlice.getInitialState(),
        mailRuntime: mailRuntimeSlice.getInitialState(),
    },
    reducers: {
        actionSetDisplayMode: (state, action: PayloadAction<DisplayMode>) => {
            state.displayMode = action.payload;
        },
        actionSetTagBank: (state, action: PayloadAction<BackendTagBank>) => {
            let bank = new TagBank();
            bank.is_backend_data = true;
            bank.tagMap = new Map();
            action.payload.tags.forEach((t) => bank.tagMap.set(t.id, t));
            state.tagBank = bank;
        },
        actionUpdateTags: (state, action: PayloadAction<Tag[]>) => {
            const tags = action.payload;
            for (let i = 0; i < tags.length; i++) {
                state.tagBank.tagMap.set(tags[i].id, tags[i]);
            }
        },
        actionUpdateTag: (state, action: PayloadAction<Tag>) => {
            const tag = action.payload;
            state.tagBank.tagMap.set(tag.id, tag);
        },
        actionRemoveTag: (state, action: PayloadAction<string>) => {
            state.tagBank.tagMap.delete(action.payload);
        },
        actionSetDaily: (state, action: PayloadAction<UserDaily>) => {
            state.summaryBank = {
                ...state.summaryBank,
                user_daily: { ...action.payload, is_backend_data: true },
            };
        },
        actionSetCalendarDays: (state, action: PayloadAction<number[]>) => {
            state.summaryBank = {
                ...state.summaryBank,
                calendar_days: { days: action.payload, is_backend_data: true },
            };
        },
        actionSetIdeas: (state, action: PayloadAction<IssueIdea[]>) => {
            state.ideaBank.ideas = action.payload;
            state.ideaBank.isBackendData = true;
        },
        actionSetCalcDisplay: (state, action: PayloadAction<string>) => {
            state.calcDisplay = action.payload;
        },
        actionSetBackdrop: (state, action: PayloadAction<boolean>) => {
            state.backdrop = action.payload;
        },
        actionAddBackdrop: (state) => {
            state.backdropCount++;
        },
        actionDecBackdrop: (state) => {
            state.backdropCount--;
        },
        actionSetWordBank: (state, action: PayloadAction<WordInfo[]>) => {
            let bank = new WordBank();
            bank.is_backend_data = true;
            bank.wordMap = new Map();
            action.payload.forEach((w) => bank.wordMap.set(w.id, w));
            state.wordBank = bank;
        },
        actionSetReport: (state, action: PayloadAction<BookReport>) => {
            state.report = {
                ...action.payload,
                is_backend_data: true,
            };
        },
        actionSetPageSource: (state, action: PayloadAction<PageSource>) => {
            state.pageSource = action.payload;
        },
        actionSetReasonLib: (state, action: PayloadAction<BackendReasonLib>) => {
            let lib = {
                question_categories: action.payload.question_categories,
                isBackendData: true,
            } as ReasonLib;
            state.reasonLib = lib;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isExamAction, (state, action) => {
                examRuntimeSlice.reducer(state.examRuntime, action);
            })
            .addMatcher(isSearchAction, (state, action) => {
                searchRuntimeSlice.reducer(state.searchRuntime, action);
            })
            .addMatcher(isUserAction, (state, action) => {
                userSlice.reducer(state.user, action);
            })
            .addMatcher(isBookAction, (state, action) => {
                bookRuntimeSlice.reducer(state.bookRuntime, action);
            })
            .addMatcher(isFlashActoin, (state, action) => {
                flashRuntimeSlice.reducer(state.flashRuntime, action);
            })
            .addMatcher(isDialogAction, (state, action) => {
                dialogRuntimeSlice.reducer(state.dialogRuntime, action);
            })
            .addMatcher(isManageActoin, (state, action) => {
                manageRuntimeSlice.reducer(state.manageRuntime, action);
            })
            .addMatcher(isPostAction, (state, action) => {
                postRuntimeSlice.reducer(state.postRuntime, action);
            })
            .addMatcher(isMailAction, (state, action) => {
                mailRuntimeSlice.reducer(state.mailRuntime, action);
            });
    },
});

export const {
    actionSetIdeas,
    actionSetDisplayMode,
    actionSetTagBank,
    actionUpdateTags,
    actionUpdateTag,
    actionRemoveTag,
    actionSetCalcDisplay,
    actionSetBackdrop,
    actionSetWordBank,
    actionSetReport,
    actionSetDaily,
    actionSetCalendarDays,
    actionSetPageSource,
    actionAddBackdrop,
    actionDecBackdrop,
    actionSetReasonLib,
} = rootSlice.actions;

export default rootSlice.reducer;
