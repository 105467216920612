import { createBrowserRouter } from "react-router-dom";
import { FooterLayout } from "./bblib/component/footer/footer";
import ErrorPage from "./page/ErrorPage";

export const bbRouter = createBrowserRouter([
    {
        path: "/",
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                async lazy() {
                    let { HomePage } = await import("./page/nav/pages/home/HomePage");
                    return { Component: HomePage };
                },
            },
            {
                path: "login",
                async lazy() {
                    let { LoginPage } = await import("./page/login/LoginPage");
                    return { Component: LoginPage };
                },
            },
            {
                path: "flash/:flashID",
                async lazy() {
                    let { FlashPage, flashLoader } = await import("./page/flash/FlashPage");
                    return {
                        loader: flashLoader,
                        Component: FlashPage,
                    };
                },
            },
            {
                path: "exam/:examID",
                async lazy() {
                    let { ExamPage, examLoader } = await import("./page/exam/ExamPage");
                    return {
                        loader: examLoader,
                        Component: ExamPage,
                    };
                },
            },
            {
                path: "report/:bookID",
                async lazy() {
                    let { ReportPage, reportLoader } = await import("./page/report/ReportPage");
                    return {
                        loader: reportLoader,
                        Component: ReportPage,
                    };
                },
            },
            {
                path: "debug",
                children: [
                    {
                        index: true,
                        async lazy() {
                            let { DebugPage } = await import("./page/DebugPage");
                            return { Component: DebugPage };
                        },
                    },
                ],
            },
            {
                path: "web_manage",
                async lazy() {
                    let { WebManagePage } = await import("./page/manage/web/WebManage");
                    return { Component: WebManagePage };
                },
            },
            {
                path: "table_manage",
                async lazy() {
                    let { OpManagePage } = await import("./page/manage/op/OpManagePage");
                    return { Component: OpManagePage };
                },
            },
            {
                path: "unfreeze",
                async lazy() {
                    let { UnFreezePage } = await import("./page/UnFreezePage");
                    return { Component: UnFreezePage };
                },
            },
            {
                path: "freeze",
                async lazy() {
                    let { FreezePage } = await import("./page/FreezePage");
                    return { Component: FreezePage };
                },
            },
            {
                path: "operate",
                async lazy() {
                    let { OperatePage } = await import("./page/OperatePage");
                    return { Component: OperatePage };
                },
            },
            {
                path: "sync",
                async lazy() {
                    let { SyncPage } = await import("./page/manage/sync/SyncPage");
                    return { Component: SyncPage };
                },
            },
            {
                path: "agreement",
                async lazy() {
                    let { AgreementPage } = await import("./page/AgreementPage");
                    return { Component: AgreementPage };
                },
            },
            {
                path: "export",
                async lazy() {
                    let { ExportPage } = await import("./page/ExportPage");
                    return { Component: ExportPage };
                },
            },
            {
                path: "nav",
                element: <FooterLayout />,
                children: [
                    {
                        path: "home",
                        async lazy() {
                            let { HomePage } = await import("./page/nav/pages/home/HomePage");
                            return { Component: HomePage };
                        },
                    },
                    {
                        path: "exercise_list",
                        async lazy() {
                            let { ExerciseListPage } = await import(
                                "./page/nav/pages/exercise/ExerciseListPage"
                            );
                            return { Component: ExerciseListPage };
                        },
                    },
                    {
                        path: "higher",
                        async lazy() {
                            let { HigherPage } = await import("./page/nav/pages/higher/HigherPage");
                            return { Component: HigherPage };
                        },
                    },
                    {
                        path: "season",
                        async lazy() {
                            let { SeasonPage } = await import("./page/nav/pages/season/SeasonPage");
                            return { Component: SeasonPage };
                        },
                    },
                    {
                        path: "word",
                        async lazy() {
                            let { WordListPage } = await import(
                                "./page/nav/pages/word/WordListPage"
                            );
                            return { Component: WordListPage };
                        },
                    },
                    {
                        path: "search",
                        async lazy() {
                            let { SearchPage } = await import("./page/nav/pages/search/SearchPage");
                            return { Component: SearchPage };
                        },
                    },
                    {
                        path: "math2",
                        async lazy() {
                            let { MathPage } = await import("./page/nav/pages/resource/MathPage");
                            return { Component: MathPage };
                        },
                    },
                    {
                        path: "idea",
                        async lazy() {
                            let { IdeaPage } = await import("./page/nav/pages/resource/IdeaPage");
                            return { Component: IdeaPage };
                        },
                    },
                    {
                        path: "compare",
                        async lazy() {
                            let { ComparePage } = await import(
                                "./page/nav/pages/compare/ComparePage"
                            );
                            return { Component: ComparePage };
                        },
                    },
                    {
                        path: "download",
                        async lazy() {
                            let { DownloadPage } = await import(
                                "./page/nav/pages/download/DownloadPage"
                            );
                            return { Component: DownloadPage };
                        },
                    },
                    {
                        path: "analyze",
                        async lazy() {
                            let { GrowthPage } = await import("./page/nav/pages/growth/GrowthPage");
                            return { Component: GrowthPage };
                        },
                    },
                    {
                        path: "personal",
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    let { PersonalPage } = await import(
                                        "./page/nav/pages/personal/PersonalPage"
                                    );
                                    return { Component: PersonalPage };
                                },
                            },
                            {
                                path: "voucher",
                                async lazy() {
                                    let { VoucherPage } = await import(
                                        "./page/nav/pages/personal/voucher/VoucherPage"
                                    );
                                    return { Component: VoucherPage };
                                },
                            },
                            {
                                path: "collect",
                                async lazy() {
                                    let { CollectPage } = await import(
                                        "./page/nav/pages/personal/CollectPage"
                                    );
                                    return { Component: CollectPage };
                                },
                            },
                            {
                                path: "wrongs",
                                async lazy() {
                                    let { WrongsPage } = await import(
                                        "./page/nav/pages/personal/WrongsPage"
                                    );
                                    return { Component: WrongsPage };
                                },
                            },
                            {
                                path: "note",
                                async lazy() {
                                    let { NotePage } = await import(
                                        "./page/nav/pages/personal/NotePage"
                                    );
                                    return { Component: NotePage };
                                },
                            },
                            {
                                path: "higher_collect",
                                async lazy() {
                                    let { HigherCollectPage } = await import(
                                        "./page/nav/pages/personal/HigherCollectPage"
                                    );
                                    return { Component: HigherCollectPage };
                                },
                            },
                            {
                                path: "higher_wrongs",
                                async lazy() {
                                    let { HigherWrongsPage } = await import(
                                        "./page/nav/pages/personal/HigherWrongsPage"
                                    );
                                    return { Component: HigherWrongsPage };
                                },
                            },
                            {
                                path: "higher_note",
                                async lazy() {
                                    let { HigherNotePage } = await import(
                                        "./page/nav/pages/personal/HigherNotePage"
                                    );
                                    return { Component: HigherNotePage };
                                },
                            },
                            {
                                path: "card",
                                async lazy() {
                                    let { CardPage } = await import(
                                        "./page/nav/pages/personal/card/CardPage"
                                    );
                                    return { Component: CardPage };
                                },
                            },
                            {
                                path: "mail",
                                async lazy() {
                                    let { MailPage } = await import(
                                        "./page/nav/pages/personal/mail/MailPage"
                                    );
                                    return { Component: MailPage };
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
