import { createSelector } from "reselect";
import { QuestionCategory } from "../../model/question/data/category";
import { Question } from "../../model/question/Question";
import { RateEventScene } from "../../model/Rate";
import { Tag, TagBank } from "../../model/TagData";
import { Collect, CollectBank } from "../../model/user/collect";
import { KindReasonInputs, QuestionCategoryReasons } from "../../model/user/reason";
import { _selectExamQuestion, selectExamShowResult } from "../exam/selector";
import { selectFlashShowResult, selectRuntimeQuestion } from "../flash/selector";
import { RootState } from "../ReduxStore";
import { DisplayMode } from "../root/reducer";
import { selectReasonLib, selectTagBank } from "../root/selector";
import { selectSearchQuestion, selectSidebarShowResult } from "../search/selector";
import { selectCollectBank } from "../user/selector";

export const selectDisplayMode = (state: RootState) => state.displayMode;

export const selectShowResult = (state: RootState) => {
    if (state.displayMode === DisplayMode.ExamDispaly) {
        const a = selectExamShowResult(state);
        return a;
    }
    if (state.displayMode === DisplayMode.SearchDispaly) {
        const a = selectSidebarShowResult(state);
        return a;
    }
    if (state.displayMode === DisplayMode.FlashDiaplay) {
        return selectFlashShowResult(state);
    }
    return false;
};

export const selectEventScene = (state: RootState): RateEventScene => {
    if (state.displayMode === DisplayMode.SearchDispaly) {
        return RateEventScene.SideBar;
    }
    if (state.displayMode === DisplayMode.ExamDispaly) {
        if (selectExamShowResult(state)) {
            return RateEventScene.DisplayAnswer;
        }
        return RateEventScene.InExam;
    }
    if (state.displayMode === DisplayMode.FlashDiaplay) {
        return RateEventScene.Flash;
    }
    return RateEventScene.None;
};

const emptyQuestion = new Question();
export const selectQuestion = (state: RootState) => {
    if (state.displayMode === DisplayMode.ExamDispaly) {
        const q = _selectExamQuestion(state);
        return q;
    }
    if (state.displayMode === DisplayMode.SearchDispaly) {
        const q = selectSearchQuestion(state);
        return q;
    }
    if (state.displayMode === DisplayMode.FlashDiaplay) {
        const q = selectRuntimeQuestion(state);
        return q;
    }
    return emptyQuestion;
};

export const selectQuestionAction = (state: RootState) => selectQuestion(state).action;
export const selectQuestionResult = (state: RootState) => selectQuestion(state).result;

export const selectQuestionData = (state: RootState) => selectQuestion(state).data;
export const selectQuestionCategory = (state: RootState) => selectQuestionData(state).category;
export const selectQuestionSupportDrawer = (state: RootState) => {
    const category = selectQuestionCategory(state);
    return (
        category === QuestionCategory.Math ||
        category === QuestionCategory.Filling ||
        category === QuestionCategory.Reading
    );
};
export const selectQuestionID = (state: RootState) => selectQuestion(state).data.id;
export const selectPassage = (state: RootState) => selectQuestionData(state).passage;
export const selectInquiry = (state: RootState) => selectQuestionData(state).inquiry;
export const selectInputMethod = (state: RootState) => selectQuestionData(state).input_method;
export const selectAnalysis = (state: RootState) => selectQuestionData(state).analysis;
export const selectAnswer = (state: RootState) => selectQuestion(state).action.answer;
export const selectStandardAnswer = (state: RootState) =>
    selectQuestionData(state).standard_answer.answer;
export const selectTagIDs = (state: RootState) => selectQuestionData(state).tag_ids;

const getTagsFromBank = (tagIDs: string[], bank: TagBank) => {
    let tags = [] as Tag[];
    for (let i = 0; i < tagIDs.length; i++) {
        const t = bank.tagMap.get(tagIDs[i]);
        if (t !== undefined) {
            tags.push(t);
        }
    }
    return tags;
};

export const selectTags = createSelector([selectTagIDs, selectTagBank], getTagsFromBank);

export const selectSynonymsTagIDs = (state: RootState) => selectAnalysis(state).synonyms;
export const selectSynonymsTags = createSelector(
    [selectSynonymsTagIDs, selectTagBank],
    getTagsFromBank
);

export const selectAntonymsTagIDs = (state: RootState) => selectAnalysis(state).antonyms;
export const selectAntonymsTags = createSelector(
    [selectAntonymsTagIDs, selectTagBank],
    getTagsFromBank
);

export const selectCraft = (state: RootState) => selectQuestion(state).craft;
export const selectCollect = (state: RootState) => selectCraft(state).collect;

function cidsToCollects(cids: string[], bank: CollectBank): Collect[] {
    let cs = [] as Collect[];
    for (let i = 0; i < cids.length; i++) {
        const cid = cids[i];
        const aims = bank.collects.filter((c) => c.id === cid);
        if (aims.length === 1) {
            cs.push(aims[0]);
        }
    }
    return cs;
}
export const selectCollectTags = createSelector([selectCollect, selectCollectBank], (c, bank) =>
    cidsToCollects(c.collect_ids, bank)
);
export const selectCollected = (state: RootState) => selectCollect(state).collected;
export const selectWrong = (state: RootState) => selectCraft(state).wrong;
export const selectCardCollected = (state: RootState) => selectCraft(state).card.collected;
export const selectNote = (state: RootState) => selectCraft(state).note;
export const selectTextInput = (state: RootState) => selectNote(state).text_input;
export const selectNoteText = (state: RootState) => selectTextInput(state).note;
export const selectReview = (state: RootState) => selectNote(state).review;
export const selectImprovement = (state: RootState) => selectReview(state).improvement;

export const selectQuestionIsWord = (state: RootState) =>
    selectQuestion(state).data.category === QuestionCategory.Word;

export const selectConnectWords = (state: RootState) => selectQuestion(state).connect_words;

export const selectReasonInputs = (state: RootState) => selectReview(state).reasons;
export const selectQuestionReasonLib = createSelector(
    [selectQuestionData, selectReasonLib],
    (q, lib) => {
        const cats = lib.question_categories.filter((cat) => cat.question_category === q.category);
        if (cats.length > 0) {
            return cats[0];
        }
        return new QuestionCategoryReasons();
    }
);

export const selectOrderedReasonInputs = createSelector(
    [selectQuestionReasonLib, selectReasonInputs],
    (lib, inputs): KindReasonInputs[] => {
        let ks = [] as KindReasonInputs[];
        for (let i = 0; i < lib.kinds.length; i++) {
            const k = lib.kinds[i];
            const kr = { ...new KindReasonInputs(), kind: k.kind, kind_desp: k.kind_desp };
            for (let j = 0; j < k.subs.length; j++) {
                const sub = k.subs[j];
                const finds = inputs.filter((input) => input.reason_id === sub.id);
                if (finds.length > 0) {
                    kr.reasons.push({ reason: sub, input: finds[0] });
                }
            }
            if (kr.reasons.length > 0) {
                ks.push(kr);
            }
        }
        return ks;
    }
);
