export class RateEvent {
    type: number = 0;
    user_id: string = "";
    question_id: string = "";
    time_stamp: number = 0;
    scene: number = 0; //  UI场景
    source: number = 0; // 入口
    exam_id: string = "";
    exam_name: string = "";
    exam_type: number = 0;
    cost_seconds: number = 0;
    question_type: number = 0;
}

export enum RateEventType {
    Question = 1,
    Analysis = 2,
    Exam = 3,
}

// 根据displayMode+exam状态计算
export enum RateEventScene {
    None = 0,
    InExam = 1, // 模考
    DisplayAnswer = 2, // 查看答案/浏览试卷
    SideBar = 3, // 搜索列表展示
    Flash = 4, // 背闪卡
}

// 从各入口进入时赋值
export enum PageSource {
    None = 0,

    Exam = 1, // 考试(包括单项练习、收藏夹练习等)
    Collect = 2, // 收藏夹
    Wrongs = 3, // 错题集
    Note = 4, // 笔记列表

    Search = 5, // 搜索
    Word = 6, // 单词
    Home = 7, // 首页(模考)
    Exercise = 8, // 单项练习
    Higher = 9, // RAPL
    Season = 10, // BB限定
    ArchivedExercise = 11, // 旧版单项练习
    Voucher = 12,
    Flash = 13, // 我的闪卡
    ArchivedPick = 15,
    Mail = 16, // 站内信列表
}

export class ReportEventsRsp {}
