import { EnumItem } from "../../Enum";
import { Answer, InputMethod } from "../data/QuestionData";
import { SearchOption } from "../search/Option";
import { BankData } from "./Bank";

export class Flash {
    id: string = "";
    user_id: string = "";
    status: number = 0;
    round: Round = new Round();
    stage_count: number = 0;
    stage_status_en: string = "";
    round_count: number = 0;
    summary: Summary = new Summary();
    all_card_count: number = 0;
    range_kind: number = 0;

    isBackend: boolean = false;
}

export class Summary {
    not_seen_progress: Progress = new Progress();
    learn_progress: Progress = new Progress();
    quiz_progress: Progress = new Progress();
    exam_progress: Progress = new Progress();
    master_progress: Progress = new Progress();
}

export class Progress {
    count: number = 0;
    progress: number = 0;
}

export class Round {
    runtimes: Runtime[] = [];
    cursor_index: number = 0;
}

export class Runtime {
    id: string = "";
    status: number = 0;
    action: Action = new Action();
    learn_input_method = new InputMethod();
    bankData: BankData = new BankData(); // 前端动态赋值
}

export class Action {
    id: string = "";
    answer: Answer = new Answer();
    mark_master: boolean = false;
}

export enum FlashStatus {
    NotStart = 0,
    Start = 1,
    Finish = 2,
}

export enum CardStatus {
    NotSeen = 0,
    Learn = 1,
    Quiz = 2,
    Exam = 3,
    Master = 4,
}

export enum OptionKind {
    None = 0,
    Remember = 1,
    Fuzzy = 2,
    Unknown = 3,
    Correct = 4,
    Wrong = 5,
}

export class CreateOption {
    scope: SearchOption = new SearchOption();
    range_kind: number = 0;
    limit: number = 0;
    exclude_used: boolean = false;
    exclude_correct: boolean = false;
    exclude_flashed: boolean = false;
}

export enum RangeKind {
    Pick = 1,
    Best = 2,
    WordFilling = 3,
    WordMath = 4,
    WordSixTwo = 5,
}

export const QuestionRangeKindItems = [
    new EnumItem(RangeKind.Pick, "精选题库"),
    new EnumItem(RangeKind.Best, "超级精选题库"),
];

export const WordRangeKindItems = [
    new EnumItem(RangeKind.WordFilling, "BB词3600"),
    // new EnumItem(RangeKind.WordSixTwo, "BB六选二"),
    new EnumItem(RangeKind.WordMath, "BB数学词"),
];

export function rangeKindTitle(rangeKind: RangeKind): string {
    if (rangeKindIsWord(rangeKind)) {
        return "单词";
    }
    return "题";
}

export function rangeKindIsWord(rangeKind: RangeKind): boolean {
    return (
        rangeKind === RangeKind.WordFilling ||
        rangeKind === RangeKind.WordMath ||
        rangeKind === RangeKind.WordSixTwo
    );
}
