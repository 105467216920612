import dayjs from "dayjs";
import { methodUpdateCraft } from "../../api/apicraft/api";
import { BookKind } from "../../model/book/Book";
import { QuestionData } from "../../model/question/data/QuestionData";
import { Question } from "../../model/question/Question";
import { Craft, Note, QuestionCollect, Source, UpdateCraftKind } from "../../model/user/user_craft";
import { selectBook } from "../book/selector";
import { actionSetVIPDialog } from "../dialog/reducer";
import { actionSetExamLocationQuestion, actionSetExamShowResult } from "../exam/reducer";
import { selectExamID } from "../exam/selector";
import { actionSetFlashLocationQuestion, actionSetFlashShowResult } from "../flash/reducer";
import { AppDispatch, RootState } from "../ReduxStore";
import { DisplayMode } from "../root/reducer";
import { actionSetSearchQuestion, actionSetSidebarShowResult } from "../search/reducer";
import {
    selectCraft,
    selectDisplayMode,
    selectQuestion,
    selectQuestionData,
    selectQuestionIsWord,
} from "./selector";

// 在调用setQuestion时用户可能已经切换到其他题了，因此题目的定位要以当时的state为准
function setQuestion(q: Question, state: RootState, dispatch: any) {
    if (state.displayMode === DisplayMode.ExamDispaly) {
        // dispatch(actionSetExamQuestion(q));
        dispatch(
            actionSetExamLocationQuestion({
                sindex: state.examRuntime.sectionIndex,
                qindex: state.examRuntime.questionIndex,
                q: q,
            })
        );
        return;
    } else if (state.displayMode === DisplayMode.SearchDispaly) {
        dispatch(actionSetSearchQuestion(q));
        return;
    } else if (state.displayMode === DisplayMode.FlashDiaplay) {
        // dispatch(actionSetFlashRuntimeQuestion(q));
        dispatch(actionSetFlashLocationQuestion({ rindex: state.flashRuntime.roundIndex, q: q }));
        return;
    }
}

export function setShowResult(dispatch: AppDispatch, state: RootState, b: boolean) {
    const qd = selectQuestionData(state);
    if (!qd.standard_answer.accessable) {
        dispatch(actionSetVIPDialog(true));
        return;
    }
    if (state.displayMode === DisplayMode.ExamDispaly) {
        dispatch(actionSetExamShowResult(b));
        return;
    } else if (state.displayMode === DisplayMode.SearchDispaly) {
        dispatch(actionSetSidebarShowResult(b));
        return;
    } else if (state.displayMode === DisplayMode.FlashDiaplay) {
        dispatch(actionSetFlashShowResult(b));
        return;
    }
}

export function setQuestionData(data: QuestionData, state: RootState, dispatch: any) {
    const q = selectQuestion(state);
    setQuestion({ ...q, data: data }, state, dispatch);
}

export function setQuestionCraft(c: Craft, state: RootState, dispatch: any) {
    const q = selectQuestion(state);
    setQuestion({ ...q, craft: c }, state, dispatch);
}

export function doUpdateNote(state: RootState, dispatch: any, setter: (m: Note) => Note) {
    const book = selectBook(state);
    const in_higher = book.template.kind === BookKind.Higher;

    return doUpdateCraft(dispatch, state, UpdateCraftKind.Note, (craft: Craft) => {
        const note = craft.note;
        const note2 = setter(note);
        return { ...craft, note: note2, in_higher: in_higher } as Craft;
    });
}

export function doUpdateCollect(
    state: RootState,
    dispatch: any,
    setter: (m: QuestionCollect) => QuestionCollect
) {
    const book = selectBook(state);
    const in_higher = book.template.kind === BookKind.Higher;
    const q = selectQuestion(state);
    const source_qid = q.data.id.startsWith("w") ? "" : q.data.id;
    const isWord = selectQuestionIsWord(state);
    const wordInfo = q.word_info;

    return doUpdateCraft(dispatch, state, UpdateCraftKind.Collect, (craft: Craft) => {
        const collect = craft.collect;
        const collect2 = setter({
            ...collect,
            word: isWord
                ? {
                      kind: wordInfo.kind,
                      word_id: wordInfo.id,
                      pair_id: wordInfo.pair_id,
                      source_qid: source_qid,
                  }
                : collect.word,
        });
        return {
            ...craft,
            collect: collect2,
            in_higher: in_higher,
        } as Craft;
    });
}

export async function doUpdateCraft(
    dispatch: AppDispatch,
    state: RootState,
    kind: UpdateCraftKind,
    setter: (craft: Craft) => Craft
) {
    const craft = selectCraft(state);
    const craft2 = setter(craft);

    return methodUpdateCraft(dispatch, {
        update_kind: kind,
        craft_id: craft2.id,
        craft: craft2,
        source: makeSource(state),
    }).then((rsp) => {
        setQuestionCraft(rsp.craft, state, dispatch);
        return rsp;
    });
}

export function makeSource(state: RootState): Source {
    const displayMode = selectDisplayMode(state);
    const book = selectBook(state);
    const examID = selectExamID(state);
    const getSourceName = () => {
        if (displayMode === DisplayMode.SearchDispaly) {
            return "搜索";
        }
        if (book.title.length > 0) {
            return book.title + " " + book.sub_title;
        }
        return "";
    };

    return {
        ...new Source(),
        name: getSourceName(),
        exam_id: examID,
        template_id: book.id,
        update_time_stamp: dayjs().unix(),
    };
}
