import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Question, QuestionInfo } from "../../model/question/Question";
import {
    Analysis,
    Answer,
    InputMethod,
    InquiryData,
    PassageData,
} from "../../model/question/data/QuestionData";
import { SearchReqData, SearchRspData } from "../../model/question/search/SearchData";
import { Craft } from "../../model/user/user_craft";

export function isSearchAction(action: AnyAction): boolean {
    return action.type.startsWith("search_runtime");
}

export const searchRuntimeSlice = createSlice({
    name: "search_runtime",
    initialState: {
        roomIn: false,
        index: 0,
        showResult: false,
        searchReqData: new SearchReqData(),
        searchRspData: new SearchRspData(),
        questionID: "",
        question: new Question(),
        selectMode: false,
        showTranslate: false,
    },
    reducers: {
        actionCleanSidebar: (state, action: PayloadAction<boolean>) => {
            state.roomIn = false;
            state.index = 0;
            state.showResult = action.payload;
            state.questionID = "";
        },
        _actionSetSearchReqData: (state, action: PayloadAction<SearchReqData>) => {
            state.searchReqData = action.payload;
        },
        _actionSetSearchRspData: (state, action: PayloadAction<SearchRspData>) => {
            state.index = 0;
            state.searchRspData = { ...action.payload, is_backend_data: true };
        },
        actionSetSidebarQuestionInfo: (
            state,
            action: PayloadAction<{ info: QuestionInfo; index?: number }>
        ) => {
            const index = action.payload.index;
            const info = action.payload.info;
            if (index !== undefined) {
                state.searchRspData.question_infos[index] = info;
                return;
            }
            for (let i = 0; i < state.searchRspData.question_infos.length; i++) {
                if (info.id === state.searchRspData.question_infos[i].id) {
                    state.searchRspData.question_infos[i] = info;
                }
            }
        },
        actionSetSidebarAllSelect: (state, action: PayloadAction<boolean>) => {
            for (let i = 0; i < state.searchRspData.question_infos.length; i++) {
                state.searchRspData.question_infos[i].selected = action.payload;
            }
        },
        actionSetSidebarRoomIn: (state, action: PayloadAction<boolean>) => {
            state.roomIn = action.payload;
        },
        actionSetSidebarIndex: (state, action: PayloadAction<number>) => {
            state.index = action.payload;
        },
        actionSetSidebarShowResult: (state, action: PayloadAction<boolean>) => {
            state.showResult = action.payload;
        },
        actionSetSidebarShowTranslate: (state, action: PayloadAction<boolean>) => {
            state.showTranslate = action.payload;
        },
        actionSetSearchQuestionID: (state, action: PayloadAction<string>) => {
            state.questionID = action.payload;
        },
        actionSetSearchQuestion: (state, action: PayloadAction<Question>) => {
            state.question = action.payload;
        },
        _actionSetSidebarCollected: (
            state,
            action: PayloadAction<{ index: number; collected: boolean }>
        ) => {
            state.question.craft.collect.collected = action.payload.collected;
        },
        _actionSetSidebarConnectWordCraft: (
            state,
            action: PayloadAction<{ id: string; c: Craft }>
        ) => {
            const q = state.question;
            for (let j = 0; j < q.connect_words.length; j++) {
                const w = q.connect_words[j];
                if (w.info.id === action.payload.id) {
                    state.question.connect_words[j].craft = action.payload.c;
                }
            }
        },
        _actionSetSidebarQuestionAnswer: (state, action: PayloadAction<Answer>) => {
            state.question.action.answer = action.payload;
        },
        _actionSetSidebarPassage: (state, action: PayloadAction<PassageData>) => {
            state.question.data.passage = action.payload;
        },
        _actionSetSidebarInquiry: (state, action: PayloadAction<InquiryData>) => {
            state.question.data.inquiry = action.payload;
        },
        _actionSetSidebarInput: (state, action: PayloadAction<InputMethod>) => {
            state.question.data.input_method = action.payload;
        },
        _actionSetSidebarAnalysis: (state, action: PayloadAction<Analysis>) => {
            state.question.data.analysis = action.payload;
        },
    },
});

export const {
    actionSetSidebarRoomIn,
    actionSetSidebarIndex,
    actionCleanSidebar,
    actionSetSidebarShowResult,
    _actionSetSidebarCollected,
    _actionSetSidebarAnalysis,
    _actionSetSidebarInput,
    _actionSetSidebarInquiry,
    _actionSetSidebarPassage,
    _actionSetSidebarQuestionAnswer,
    _actionSetSidebarConnectWordCraft,
    actionSetSearchQuestionID,
    actionSetSearchQuestion,
    _actionSetSearchReqData,
    _actionSetSearchRspData,
    actionSetSidebarQuestionInfo,
    actionSetSidebarAllSelect,
} = searchRuntimeSlice.actions;

export default searchRuntimeSlice.reducer;
