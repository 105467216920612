import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { GetMailsReq, GetMailsRsp } from "../../api/apimail/mail";
import { Inbox, Mail } from "../../model/statistic/mail/mail";

export function isMailAction(action: AnyAction): action is AnyAction {
    return action.type.startsWith("mail_runtime");
}

export const mailRuntimeSlice = createSlice({
    name: "mail_runtime",
    initialState: {
        inbox: new Inbox(),
        mailsReq: new GetMailsReq(),
        mailsRsp: new GetMailsRsp(),
    },
    reducers: {
        actionSetInbox: (state, action: PayloadAction<Inbox>) => {
            state.inbox = { ...action.payload, isBackendData: true };
        },
        actionSetMailsReq: (state, action: PayloadAction<GetMailsReq>) => {
            state.mailsReq = action.payload;
        },
        actionSetMailsRsp: (state, action: PayloadAction<GetMailsRsp>) => {
            state.mailsRsp = { ...action.payload, isBackendData: true };
        },
        actionSetMailList: (state, action: PayloadAction<Mail[]>) => {
            state.mailsRsp.mails = action.payload;
        },
    },
});

export const { actionSetInbox, actionSetMailsRsp, actionSetMailsReq, actionSetMailList } =
    mailRuntimeSlice.actions;

export default mailRuntimeSlice.reducer;
