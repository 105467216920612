import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import { Autocomplete, Box, Button, Chip, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { methodAddCollect } from "../../../../../api/apicraft/api";
import { BBDialog } from "../../../../../bblib/component/dialog/BBDialog";
import { Help } from "../../../../../bblib/component/help/help";
import { AlertSuccess, AlertWarning } from "../../../../../bblib/component/Snackbar";
import { sleep } from "../../../../../bblib/tools/time";
import { Collect, CollectBank } from "../../../../../model/user/collect";
import { Craft, UpdateCraftKind } from "../../../../../model/user/user_craft";
import { acitonSetCollectDialog } from "../../../../../store/dialog/reducer";
import { selectCollectDialog } from "../../../../../store/dialog/selector";
import { useAppDispatch } from "../../../../../store/hooks";
import { actionUpdateCraft } from "../../../../../store/question/action_craft";
import { selectCollectTags } from "../../../../../store/question/selector";
import { actionSetCollectBank } from "../../../../../store/user/reducer";
import { selectCollectBank } from "../../../../../store/user/selector";
import { Render, RenderDisplay } from "../../source/renderer";

export function CollectDialog() {
    const dispatch = useAppDispatch();

    const open = useSelector(selectCollectDialog);
    const setOpen = (b: boolean) => {
        dispatch(acitonSetCollectDialog(b));
    };

    const bank = useSelector(selectCollectBank);

    const [createTagName, setCreateTagName] = useState("");

    const [inputTags, setInputTags] = useState<Collect[]>([]);

    const originCollects = useSelector(selectCollectTags);
    useEffect(() => {
        setInputTags(originCollects);
        setCreateTagName("");
    }, [originCollects]);

    return (
        <BBDialog
            title={"添加收藏标签"}
            open={open}
            setOpen={setOpen}
            confirmTitle="保存"
            onConfirm={() => {
                dispatch(
                    actionUpdateCraft(UpdateCraftKind.Collect, (craft: Craft) => {
                        let craft2 = structuredClone(craft);
                        craft2.collect.collect_ids = inputTags.map((c) => c.id);
                        craft2.collect.collected = true;
                        return craft2;
                    })
                );
            }}
            content={
                <>
                    <h3>选择已有标签</h3>
                    <Box sx={{ width: "300px" }}>
                        <Autocomplete
                            size="small"
                            options={bank.collects}
                            multiple
                            getOptionLabel={(c) => c.name}
                            renderInput={(params) => <TextField {...params} />}
                            renderTags={(collects: readonly Collect[], getTagProps) =>
                                collects.map((c, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                        <Chip
                                            variant="outlined"
                                            label={c.name}
                                            key={key}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                            value={inputTags}
                            onChange={(_, collects: Collect[]) => {
                                setInputTags(collects);
                            }}
                        />

                        <Box sx={{ mt: "50px" }} />
                        <h3>
                            <Help
                                helpText="此处创建自定义标签（不超过10个字），例如：重做第二遍、易错、时间管理、高频题、高分关键题、易被坑"
                                ml={"10px"}
                            >
                                新建标签
                            </Help>
                        </h3>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={createTagName}
                            onChange={(e) => setCreateTagName(e.target.value)}
                        />
                        <Button
                            sx={{ ml: "20px" }}
                            onClick={() => {
                                if (createTagName.length === 0) {
                                    AlertWarning("请填写标签名");
                                    return;
                                }
                                methodAddCollect(dispatch, { name: createTagName, desp: "" }).then(
                                    (rsp) => {
                                        const bank2 = structuredClone(bank);
                                        bank2.collects.push(rsp.collect);
                                        dispatch(actionSetCollectBank(bank2));
                                        sleep(100).then(() => {
                                            const input2 = structuredClone(inputTags);
                                            input2.push(rsp.collect);
                                            setInputTags(input2);
                                            setCreateTagName("");
                                            AlertSuccess("创建成功");
                                        });
                                    }
                                );
                            }}
                        >
                            创建
                        </Button>
                    </Box>
                </>
            }
        />
    );
}

export function CollectsInput(props: {
    bank: CollectBank;
    value: Collect[];
    setValue: (v: Collect[]) => void;
}) {
    const { value, setValue } = props;
    const bank = useSelector(selectCollectBank);

    return (
        <Autocomplete
            size="small"
            multiple
            options={bank.collects}
            getOptionLabel={(c) => c.name}
            value={value}
            onChange={(_, collects: Collect[]) => {
                setValue(collects);
            }}
            renderInput={(params) => <TextField {...params} />}
            renderTags={(collects: readonly Collect[], getTagProps) =>
                collects.map((c, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <Chip variant="outlined" label={c.name} key={key} {...tagProps} />;
                })
            }
        />
    );
}

export function CollectTagsDisplay(props: { Render?: Render }) {
    const { Render } = props;

    return (
        <RenderDisplay
            props={{
                title: "收藏标签",
                value: <CollectTagsDisplayContent />,
                Icon: StarIcon,
            }}
            Render={Render}
        />
    );
}

function CollectTagsDisplayContent() {
    const dispatch = useAppDispatch();

    const collects = useSelector(selectCollectTags);

    const openDialog = () => {
        dispatch(acitonSetCollectDialog(true));
    };
    return (
        <>
            {collects.length > 0 ? (
                <>
                    {collects.map((c) => (
                        <Chip label={c.name} />
                    ))}
                    <IconButton onClick={openDialog}>
                        <EditIcon />
                    </IconButton>
                </>
            ) : (
                <Button color="bbgray" size="small" variant="outlined" onClick={openDialog}>
                    添加标签
                </Button>
            )}
        </>
    );
}
