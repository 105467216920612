import { RoleKind } from "../../model/Role";
import { RootState } from "../ReduxStore";

export const selectUserRuntime = (state: RootState) => state.user;

export const selectTokenAuth = (state: RootState) => selectUserRuntime(state).auth;
export const selectHasToken = (state: RootState) =>
    selectUserRuntime(state).auth.user_id.length > 0;
export const selectEditMode = (state: RootState) => selectUserRuntime(state).editMode;
export const selectSelectMode = (state: RootState) => selectUserRuntime(state).selectMode;
export const selectHighlightMode = (state: RootState) => selectUserRuntime(state).highlightMode;
export const selectLogin = (state: RootState) => selectUserRuntime(state).login;

export const selectUser = (state: RootState) => selectUserRuntime(state).data;
export const selectUserID = (state: RootState) => selectUser(state).id;
export const selectUserName = (state: RootState) => selectUser(state).name;
export const selectUserMark = (state: RootState) => selectUser(state).mark;
export const selectAuth = (state: RootState) => selectUser(state).authority;
export const selectIsFreeze = (state: RootState) => selectAuth(state).is_freeze;
export const selectRoleKind = (state: RootState) => selectAuth(state).role_kind;
export const selectIsSVIP = (state: RootState) => selectRoleKind(state) === RoleKind.SVIP;
export const selectFlashCoins = (state: RootState) => selectAuth(state).flash_coins;

export const selectAdminMode = (state: RootState) => {
    const rt = selectUserRuntime(state);
    const user = rt.data;
    return user.is_admin && rt.adminMode;
};
export const selectUserIsAdmin = (state: RootState) => {
    return selectUser(state).is_admin;
};

export const selectShowTranslate = (state: RootState) => selectUserRuntime(state).showTranslate;
export const selectDisplayTranslate = (state: RootState) => {
    return selectShowTranslate(state) || selectHighlightMode(state);
};
export const selectIsOperator = (state: RootState) => selectAuth(state).is_operator;
export const selectIsTeacher = (state: RootState) => selectAuth(state).is_teacher;

export const selectUserInfoBank = (state: RootState) => selectUserRuntime(state).infoBank;
export const selectCollectBank = (state: RootState) => selectUserRuntime(state).collectBank;
