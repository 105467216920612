export class Collect {
    id: string = "";
    name: string = "";
    desp: string = "";
}

export class CollectBank {
    collects: Collect[] = [];
    isBackend: boolean = false;
}
