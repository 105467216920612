import { API, apiRequest } from "../api";
import { AddCollectReq, AddCollectRsp, GetCollectBankReq, GetCollectBankRsp } from "./collect";
import { GetReasonLibReq, GetReasonLibRsp, UpdateCraftReq, UpdateCraftRsp } from "./craft";

const apiUpdateCraft: API<UpdateCraftReq, UpdateCraftRsp> = {
    url: "/api/craft/update_v2",
};

export function methodUpdateCraft(dispatch: any, req: UpdateCraftReq) {
    return apiRequest({ api: apiUpdateCraft, req, dispatch });
}

const apiGetReasonLib: API<GetReasonLibReq, GetReasonLibRsp> = {
    url: "/api/craft/get_reason_lib",
};

export function methodGetReasonLib(dispatch: any, req: GetReasonLibReq) {
    return apiRequest({ api: apiGetReasonLib, req, dispatch });
}

const apiAddCollect: API<AddCollectReq, AddCollectRsp> = {
    url: "/api/collect/add",
};

export function methodAddCollect(dispatch: any, req: AddCollectReq) {
    return apiRequest({ api: apiAddCollect, req, dispatch, backdrop: true });
}

const apiGetCollectBank: API<GetCollectBankReq, GetCollectBankRsp> = {
    url: "/api/collect/get_all",
};

export function methodGetCollectBank(dispatch: any, req: GetCollectBankReq) {
    return apiRequest({ api: apiGetCollectBank, req, dispatch });
}
