import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinishProgress } from "../../api/apiflash/api";
import { Book } from "../../model/book/Book";
import { Answer } from "../../model/question/data/QuestionData";
import { Bank, BankData } from "../../model/question/flash/Bank";
import { Action, Flash, Summary } from "../../model/question/flash/Flash";
import { Question } from "../../model/question/Question";

export function isFlashActoin(action: AnyAction): boolean {
    return action.type.startsWith("flash_runtime");
}

export class FlashBank {
    isBackend: boolean = false;
    dataMap: Map<string, BankData> = new Map();
}

export enum FlashStage {
    StartIntro = 0,
    InFlash = 1,
    RoundSummary = 2,
    Result = 3,
}

export const flashRuntimeSlice = createSlice({
    name: "flash_runtime",
    initialState: {
        flashID: "",
        flash: new Flash(),
        bank: new FlashBank(),
        book: new Book(),
        roundIndex: 0,
        stage: FlashStage.StartIntro,
        showResult: false,
        finishProgress: new FinishProgress(),
        fromURL: "",
    },
    reducers: {
        actionCleanFlashRutime: (state) => {
            state.flashID = "";
            state.flash = new Flash();
            state.bank = new FlashBank();
            state.roundIndex = 0;
            state.stage = FlashStage.StartIntro;
        },
        actionSetFlashIDOrigin: (state, action: PayloadAction<string>) => {
            state.flashID = action.payload;
        },
        actionSetFlashStage: (state, action: PayloadAction<FlashStage>) => {
            state.stage = action.payload;
        },
        actionSetFlashFromURL: (state, action: PayloadAction<string>) => {
            state.fromURL = action.payload;
        },
        actionSetFlashBank: (state, action: PayloadAction<{ flash: Flash; bank: Bank }>) => {
            // set flash
            state.flash = action.payload.flash;
            state.flash.isBackend = true;

            // set bank
            let bank = new FlashBank();
            bank.isBackend = true;
            bank.dataMap = new Map();
            action.payload.bank.datas.forEach((d) => bank.dataMap.set(d.question.id, d));
            state.bank = bank;

            // init runtime
            for (let i = 0; i < state.flash.round.runtimes.length; i++) {
                const id = state.flash.round.runtimes[i].id;
                const q = state.bank.dataMap.get(id);
                if (q === undefined) {
                    state.flash.round.runtimes[i].bankData = new BankData();
                } else {
                    state.flash.round.runtimes[i].bankData = q;
                }
            }

            // set flash id
            state.flashID = action.payload.flash.id;

            // clean state
            state.roundIndex = action.payload.flash.round.cursor_index;
        },
        actionSetFlash: (state, action: PayloadAction<Flash>) => {
            // set flash
            state.flash = action.payload;
            state.flash.isBackend = true;

            // init runtime
            for (let i = 0; i < state.flash.round.runtimes.length; i++) {
                const id = state.flash.round.runtimes[i].id;
                const q = state.bank.dataMap.get(id);
                if (q === undefined) {
                    state.flash.round.runtimes[i].bankData = new BankData();
                } else {
                    state.flash.round.runtimes[i].bankData = q;
                }
            }

            // set flash id
            state.flashID = action.payload.id;

            // clean state
            state.roundIndex = action.payload.round.cursor_index;
        },
        actionSetFlashBook: (state, action: PayloadAction<Book>) => {
            state.book = action.payload;
        },
        actionSetRoundIndex: (state, action: PayloadAction<number>) => {
            state.roundIndex = action.payload;
        },
        actionSetRuntimeAnswer: (state, action: PayloadAction<Answer>) => {
            state.flash.round.runtimes[state.roundIndex].action.answer = action.payload;
        },
        actionSetRuntimeAction: (state, action: PayloadAction<Action>) => {
            state.flash.round.runtimes[state.roundIndex].action = action.payload;
        },
        actionSetFlashShowResult: (state, action: PayloadAction<boolean>) => {
            state.showResult = action.payload;
        },
        actionSetFlashFinishProgress: (state, action: PayloadAction<FinishProgress>) => {
            state.finishProgress = action.payload;
        },
        actionSetFlashRuntimeQuestion: (state, action: PayloadAction<Question>) => {
            const q = action.payload;
            state.flash.round.runtimes[state.roundIndex].bankData.question = q;
            const bankData = state.bank.dataMap.get(q.id);
            if (bankData !== undefined) {
                state.bank.dataMap.set(q.id, { ...bankData, question: q });
            }
        },
        actionSetFlashLocationQuestion: (
            state,
            action: PayloadAction<{ rindex: number; q: Question }>
        ) => {
            const q = action.payload.q;
            const rindex = action.payload.rindex;
            state.flash.round.runtimes[rindex].bankData.question = q;
            const bankData = state.bank.dataMap.get(q.id);
            if (bankData !== undefined) {
                state.bank.dataMap.set(q.id, { ...bankData, question: q });
            }
        },
        actionSetFlashSummary: (state, action: PayloadAction<Summary>) => {
            state.flash.summary = action.payload;
        },
    },
});

export const {
    actionSetFlashIDOrigin,
    actionSetFlashBank,
    actionCleanFlashRutime,
    actionSetFlashBook,
    actionSetRuntimeAnswer,
    actionSetRoundIndex,
    actionSetFlash,
    actionSetFlashStage,
    actionSetFlashShowResult,
    actionSetFlashFinishProgress,
    actionSetFlashRuntimeQuestion,
    actionSetFlashSummary,
    actionSetFlashFromURL,
    actionSetRuntimeAction,
    actionSetFlashLocationQuestion,
} = flashRuntimeSlice.actions;
